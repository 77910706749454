import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from "../components/layout"

import Art1 from '../images/art/art1.jpg';
import Art2 from '../images/art/art2.jpg';
import Art3 from '../images/art/art3.jpg';
import Art4 from '../images/art/art4.jpg';
import Art5 from '../images/art/art5.jpg';

const OtherPage = ({ data: { site: { siteMetadata: { title, siteUrl } } }, location: { pathname } }) => {
  return (
    <Layout>
      <div className="ProjectsPage">
        <Helmet
          title={`Other | ${title}`}
          meta={[
            { property: 'og:title', content: `Other | ${title}` },
            { property: 'og:url', content: `${siteUrl}${pathname}` },
          ]}
        />

        <h1 className="hi">Art</h1>

        <div className="ProjectSection">
          <h3>
            Posthuman Posters and Concepts (2017)
          </h3>
          <p>
            <img src={Art1} alt="Posthuman game poster" />
          </p>
          <p>
            <img src={Art2} alt="Posthuman game poster" />
          </p>
          <p>
            <img src={Art3} alt="Posthuman game poster" />
          </p>
          <p>
            <img src={Art4} alt="Posthuman game poster" />
          </p>
          <p>
            <img src={Art5} alt="Posthuman game poster" />
          </p>
        </div>
        
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

export default OtherPage
